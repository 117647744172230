






















































import { Component, Prop, Vue } from "vue-property-decorator";
import { IStrDetailValue, IMedia, IMediaDynamic } from "@/Services/Interfaces";
import {
  globalUpdateMediaService,
  globalUpdateDetailService
} from "@/Services/GlobalService";
import BaseSubComponent from "./BaseSubComponent.vue";
import ActionComponent from "./ActionComponent.vue";
import router from "@/router";

@Component({ components: { BaseSubComponent, ActionComponent } })
export default class AudioComponent extends BaseSubComponent {


  constructor() {
    super();
    this.name = "AudioComponent";
    this.thismodel = <IStrDetailValue>{};

  }

  name: string;
  // model ist in bassubcomponent
  @Prop()
  medias: IMedia[]|undefined;

  thismodel: IStrDetailValue;

  IsMultiAudios(): boolean {
   return this.thismedias().length > 1;
  }




  linkadd : IMediaDynamic | undefined;


    getAudio(item: IMedia): string {
    try
    {
      return item.link + (this.linkadd != undefined && this.linkadd.id == item.id && this.linkadd.query != undefined ? this.linkadd.query : "");
    } catch (e)
    {
      return item.link;
    }
  }

  doDiashow(): void {
    router.push('/gallery/'+ this.thismodel.base_id );
    // var url =      "//stefhorst.de/Diashow.html?imageurl=../images/jpg/" +     this.thismodel.base_id;
    // window.open(url, "diashow");
    
  }

  onUpdate(model: any) {
    if (this.canUpdate(model)) {
      {
        globalUpdateDetailService.sendMessage(model);
      }
    }
  }

  data() {
    return {
      thismodel: this.model,
      linkadd:  undefined,
      canAction: false
    };
  }

  thismedias(): IMedia[] {
    if (!this.medias) return [];
    return this.medias.filter(t => t.typ && t.typ.toLowerCase() == "audio");
  }
  created() {
    globalUpdateMediaService.getMessage().subscribe(t => {
        var add = t.media as  IMediaDynamic ;
        add.query = "?" + new Date().getMilliseconds().toString();
        this.linkadd = add;

    });
  }
  mounted() {}
  computed() {
    // this.model.memovalue =      "http://stefhorst.de/images/gifs/210/62316_WP_20181026_005.gif";
  }
}
